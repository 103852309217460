import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {store} from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { interfaceUrl } from './Services/env';
const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = ()=>{

    const getFirstPath = (path)=>{
        let paths = path.split("/");
        return paths[1];
    }

    const [pathstate, setpathstate] = useState(0);
    const [basepath, setbasepath] = useState("");

    useEffect(()=>{
        const host = window.location.host;
        const pathname = window.location.pathname;
        if(host==interfaceUrl){
            let path = getFirstPath(pathname);
            setpathstate(1);
            setbasepath(path);
        }
    },[])

    return (
        <>
            {pathstate==0?
                <>
                    <BrowserRouter>
                        <Routes>
                            <Route path='/*' element={<App />}>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </>:
                <>
                    <BrowserRouter>
                        <Routes>
                            <Route path={"/"+basepath+"/*"} element={<App />}>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </>
            }
        </>
    )


}


root.render(
  <React.StrictMode>
    <Provider store={store}>
        <Main></Main>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
