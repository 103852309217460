import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
            getAppElements, 
            getAppVariables, 
            jsoncompare, 
            selectAppElements, 
            selectTemplateLoadStatus,
            getAppPipelines,
            getAppclasses,
            selectAppVariables,
            selectAppPipelines
        } from "../../Services/apptemplates/apptemplates.slice";
import { runPipelines } from "../../Services/pipelines/pipeline.slice";
import {getKey} from "./utils";
import ElementContainer from "./ElementContainer";

const AppContainer = ({
                        templateid, 
                        indexarr, 
                        elmkey,
                        type,
                        childs,
                        position,
                        templateinteractions,
                        path
                    })=>{

    
    
    const [containerinit, setContainerinit] = useState("");

    const [variablesloaded, setVariablesloaded] = useState(false);

    const [onloadpipes, setOnloadpipes] = useState(false);

    const dispatch = useDispatch();

    const parentloadstatus = useSelector((state)=>{ return selectTemplateLoadStatus(state, elmkey)})

    useEffect(()=>{
        if(templateid!=undefined&&indexarr!==undefined&&containerinit!=templateid&&parentloadstatus==true){
            dispatch(getAppElements({
                "templateid": templateid,
                "indexarr": indexarr,
                "elmkey": elmkey
            }));
            dispatch(getAppVariables({
                "templateid": templateid,
                "key": getKey(templateid, indexarr),
                "elmkey": elmkey,
                "indexarr": indexarr
            }))
            dispatch(getAppPipelines({
                "templateid": templateid,
                "key": getKey(templateid, indexarr),
                "elmkey": elmkey,
                "indexarr": indexarr
            }))
            dispatch(getAppclasses(
                {
                    "templateid": templateid,
                    "key": getKey(templateid, indexarr),
                    "elmkey": elmkey,
                    "indexarr": indexarr
                }
            ))
            setContainerinit(""+templateid);
        }
    },[templateid, indexarr, parentloadstatus])

    // const {type} = useParams();

    const elements = useSelector((state)=>{return selectAppElements(state, getKey(templateid, indexarr))}, jsoncompare);

    const variables = useSelector((state)=>{return selectAppVariables(state, getKey(templateid, indexarr))}, jsoncompare);

    const pipelines = useSelector((state)=>{return selectAppPipelines(
                                                                        state, 
                                                                        getKey(templateid, indexarr),
                                                                        {}
                                                                    )}, jsoncompare);

    useEffect(()=>{
        if(containerinit&&variables!=undefined){
            setVariablesloaded(true);
        }
    },[variables])

    useEffect(()=>{
        if(containerinit&&variablesloaded&&pipelines!=undefined&&onloadpipes==false){
            for(let i=0; i< pipelines.length; i++){
                if(pipelines[i].onload){
                    console.log("running onload pipelines "+pipelines[i]._id);
                    dispatch(runPipelines({
                        key: getKey(templateid, indexarr),
                        pipelineid: pipelines[i]._id,
                        schemamapping: []
                    }))
                }
            }
            setOnloadpipes(true);
        }
    },[pipelines,variablesloaded])

    return (
        <>
            {type=="html"&&
                <>
                    {elements.length>0&&indexarr!=undefined&&
                        <div>
                            {elements[1].childs.map((element,i)=>{
                                return (
                                    <ElementContainer
                                        element = {element}
                                        elmkey = {getKey(templateid, indexarr)}
                                        indexarr = {[1, i]}
                                        position = {[1, i]}
                                        type={type}
                                        childs={childs}
                                        parentkey={elmkey}
                                        parentposition={position}
                                        parentindexarr={indexarr}
                                        templateinteractions={templateinteractions}
                                        path={path}
                                    >
                                    </ElementContainer>
                                )
                            })}   
                        </div>
                    }    
                </>
            }
        </>
    )
}

export default AppContainer;