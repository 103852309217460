import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { showError } from "../error.slice";
import {executeLoop} from "./server/pipeline";
import {
    setTemplateVariable, 
    getAppObjectVal, 
    getLocalStorageSchema, 
    getSessionStorage, 
    generateLoopVaraibleP
} from "../apptemplates/apptemplates.slice";
import * as utils from "./server/utils";

import { generateSchema } from "./server/utils";

export const loadTemplateVariables = (
    type,
    templateid,
    getState,
    delineatedPipeline,
    source,
    position,
    event
)=>{
    if(type=="main"){
        let stateCopy = getState();
        let templatevariablesCopy = JSON.parse(JSON.stringify(stateCopy.apptemplates.appvariables));
        let schema = [];
        let output = {};
        if(templatevariablesCopy[templateid]!=undefined){
            let varaibleschema = templatevariablesCopy[templateid].schema;
            output["variable"] = varaibleschema[0].value
            schema.push(varaibleschema[0]);
        }
        //load inputs
        let appinputs = JSON.parse(JSON.stringify(stateCopy.apptemplates.appinputs));
        let templateinputs = appinputs[templateid];
        if(templateinputs!=undefined){
            let inputschema = {
                "key": "inputs",
                "label": "Inputs",
                "type": "object",
                "subschema": [...templateinputs],
                "value": {}
            }
            schema.push(inputschema)
            output["inputs"] = {};
        }
        //load session storage
        let localStorageSchema = getLocalStorageSchema();
        schema.push(localStorageSchema[0]);
        output["localstorage"] = {};
        //load session storage
        let sessionStorageSchema = getSessionStorage();
        schema.push(sessionStorageSchema[0]);
        output["sessionstorage"] = {};
        if(source=="pipeline"){
            // load loop variable schema
            let elements = stateCopy.apptemplates.appelements[templateid];
            let loopschema = generateLoopVaraibleP(elements,templatevariablesCopy[templateid].schema, position);
            if(loopschema!=undefined&&loopschema.length>0){
                schema.push(loopschema[0]);
                output["loopvariable"] = {};
                //load event schema;
                let eventschema = [];
                generateSchema(event, eventschema);
                schema.push(eventschema)
                output["event"] = {};
            }
               
        }
        delineatedPipeline["template"] = {
                                          outputschema: schema,
                                          output: output
                                         };
        
        return delineatedPipeline;
    }else{

    }

}

const checkoverride = (
    key, 
    pipelineid,
    apptemplates,
    interactionmapping,
    position
)=>{
    let appvariable = apptemplates.appvariables[key];
    if(appvariable.parent==""||appvariable.parent==undefined){
        return {
            newkey:key,
            newpipelineid: pipelineid,
            interactionmapping: interactionmapping,
            position: position
        }
    }else{
        let parent = appvariable.parent;
        let indexarr = key.split("__");
        indexarr = indexarr.slice(1, indexarr.length);
        let parentelements = apptemplates.appelements[parent];
        for(let i=0; i<indexarr.length; i++){
            if(i==(indexarr.length-1)){
                parentelements = parentelements[indexarr[i]];
                let interactions = parentelements.interactions;
                let pipelineindex = _.findIndex(interactions, (interaction)=>{return interaction.id==pipelineid});
                if(pipelineindex>-1){
                    let resp = checkoverride(
                                                parent, 
                                                interactions[pipelineindex].pipeline, 
                                                apptemplates, 
                                                interactions[pipelineindex].schemamapping,
                                                indexarr
                                            )
                    return resp;
                }else{
                    return {
                        newkey: key,
                        newpipelineid: pipelineid,
                        interactionmapping: interactionmapping,
                        position: position
                    }
                }
            }else{
                parentelements = parentelements[indexarr[i]].childs;
            }
        }
    }
}

const modifyInteractionPipelineSchemamapping = (pipeline, schemamapping)=>{
        pipeline.pipeline[0] = {...pipeline.pipeline[0],
                                inputschemamapping: schemamapping
                               }
}

export const runPipelines = createAsyncThunk(
    "pipeline/runpipelines",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let delineatedpipeline = {};
            let key = payload.key;
            let pipelineid = payload.pipelineid;
            let event = payload.event;
            let position = payload.position;
            let apptemplatesstate = getState().apptemplates;
            if(payload.source=="appevents"){
                let interactionmapping = payload.schemamapping;
                let resp = checkoverride(
                                            key, 
                                            pipelineid, 
                                            apptemplatesstate,
                                            interactionmapping,
                                            position
                                        );
                loadTemplateVariables("main", resp.newkey, getState, delineatedpipeline, "pipeline", resp.position, event);
                let templatepipelines = JSON.parse(JSON.stringify(apptemplatesstate.templatepipelines[resp.newkey]));
                let pipelineIndex = _.findIndex(templatepipelines, (tp)=>{return tp._id==resp.newpipelineid})
                let pipeline = templatepipelines[pipelineIndex];
                modifyInteractionPipelineSchemamapping(pipeline, resp.interactionmapping);
                let res;
                const callback = (resp)=>{
                    res = resp;
                }
                let inputschema = pipeline.pipeline[0].inputschema;
                let inputschemamapping = pipeline.pipeline[0].inputschemamapping;
                let params = await utils.parseSchema(inputschema, inputschemamapping, delineatedpipeline);
                await executeLoop(pipeline, params, delineatedpipeline, callback,resp.newkey, dispatch, getState);
            }else{
                loadTemplateVariables("main", key, getState, delineatedpipeline, "pipeline", position, event);
                let templatepipelines = JSON.parse(JSON.stringify(apptemplatesstate.templatepipelines[key]));
                let pipelineIndex = _.findIndex(templatepipelines, (tp)=>{return tp._id==pipelineid})
                let pipeline = templatepipelines[pipelineIndex];
                let res;
                const callback = (resp)=>{
                    res = resp;
                }
                let inputschema = pipeline.pipeline[0].inputschema;
                let inputschemamapping = pipeline.pipeline[0].inputschemamapping;
                let params = await utils.parseSchema(inputschema, inputschemamapping, delineatedpipeline);
                await executeLoop(pipeline, params, delineatedpipeline, callback, key, dispatch, getState);
            }
            
        }catch(error){
            dispatch(showError("Error executing pipeline"));
            throw error;
        }
    }
)

const pipelineSlice = createSlice({
    "name": "pipeline",
    "initialState":{

    }
})

export default pipelineSlice.reducer;